import React, { useState, useEffect, useRef } from "react";
import { Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import SelectAsyncPaginate from "components/selectAsyncPaginate/index.tsx";
import SelectInput from "components/selectInput";
import Select from "react-select";
import makeAnimated from "react-select/animated";

import { useUserData } from "contexts/AuthContextManagement";
import { useFilterData } from "contexts/FilterContextManagment";
import request from "services/request";

import CloseIcon from "assets/images/close.png";
import DeleteIcon from "assets/images/icon/DELETE_WHITE.svg";

import "react-datepicker/dist/react-datepicker.css";
import { NEW_INGREDIENT_CATEGORIES } from "common/constants";
import tableIngredientsDummy from "../../../data/ingredients.json";
import tableProviderIngredientsDummy from "../../../data/provider_ingredients.json";

/**
 * function to render the filter screen of the sctock page
 * @param {*} param0
 * @returns UI component
 */

function ProviderIngredientRightSide({ onApply: onSubmit, formData: fd }) {
  const { t } = useTranslation();
  const {
    eventTypeData,
    setEventTypeData,
    selectedProviderIngredients,
    setSelectedProviderIngredients,
    selectedProviders, 
    setSelectedProviders,
    selectedCategories,
    setSelectedCategories,
    isFilterActive,
    updateFilterButton
  } = useFilterData();

  const {
    isFilterShown,
    isRestaurantLoaded,
    hasRetaurants,
    setFilterShown,
    selectedRestaurant,
    selectedRestaurantId,
  } = useUserData();

  const selectProvIngredientRef = useRef(null);
  const [selectedProviderOption, setSelectedProviderOption] = useState( selectedProviders ?? []);
  const [selectedProviderIngreOption, setSelectedProviderIngreOption] = useState( selectedProviderIngredients ?? []);

  const [category, setCategory] = useState(selectedCategories ?? {});
  const [formData, setFormData] = useState(eventTypeData ?? {});
  const [providers, setProviders] = useState([]);

  const providersOptions = providers?.map((obj) => ({
    value: obj.id,
    label: obj.name,
  }));

  const onProviderSelect = () => (v) => {
    setSelectedProviderIngreOption([...v]);
  };

  useEffect(() => {
    onClearAll();
  }, [selectedRestaurantId]);

  useEffect(() => {
    if (isRestaurantLoaded && !hasRetaurants) {
      return;
   }
    // to get providers list
    getProviderIngredients();
    setSelectedProviderIngreOption(selectedProviderIngredients);
    setCategory(selectedCategories);
    setSelectedProviderOption(selectedProviders);
    onApply();
  }, [])

  const getProviderIngredients = async() => {
    const payload = {
      restaurant_id: selectedRestaurantId,
    };
    const response = await request.get(`providers`, payload);
    setProviders(response.providers);
  };

  /**
   * submits the selected filters to api
   * @param {*} isInitial
   */
  const onApply = (isInitial = false) => {
    const force = typeof isInitial === "boolean" ? isInitial : false; // use to forcefully send default filter states/values on clear button
    const payload = JSON.parse(
      JSON.stringify({ ...(isInitial ? {} : formData) })
    );

    onSubmit({
      provider_ingredients: selectedProviderIngreOption.map((s) => s.id),
      providers: selectedProviderOption.map((s) => s.value),
      category: category,
      ...payload,

      ...(force && {
        providers: [],
        provider_ingredients: [],
        category: {}
      }),
    });
  };

  const onClearAll = (apply = true) => {
    setFormData({});
    onClearName();
    onClearCategories();
    onClearProviderName();
    setSelectedProviderOption([]);
    if (apply) onApply(true);
  };

  const onClearCategories = () => {
    setCategory(null);
  };

  const onSaveFilter = () => {
    setEventTypeData(formData);
    setSelectedCategories(category);
    setSelectedProviders(selectedProviderOption);
    setSelectedProviderIngredients(selectedProviderIngreOption);
    updateFilterButton(true);
  }

  const options = tableProviderIngredientsDummy.provider_ingredients?.map((m) => ({
    name: m.provider.name,
    label: m.provider.name,
    item_id: m.provider.name
  }));

  const providerOptions = tableProviderIngredientsDummy.provider_ingredients?.map((m) => ({
    name: m.name,
    label: m.name,
    item_id: m.name
  }));

  const onSelectionChange = (type) => ({ id }) => {
    setCategory({ [type]: id });
 };

 const onClearName = () => {
  setSelectedProviderIngreOption([]);
 }
 
 const onClearProviderName = () => {
  if (selectProvIngredientRef?.current) {
    selectProvIngredientRef.current.clearValue();
  }
 }

 const colourStyles = {
  option: (styles, { data, isDisabled, isFocused, isSelected, ...args }) => {
    return {
      ...styles,
      backgroundColor: isDisabled
        ? undefined
        : isSelected
        ? data.color
        : isFocused
        ? "#F3F4FB"
        : undefined,
      color: isDisabled ? "#ccc" : isSelected ? "black" : "black",
    };
  },
  multiValueLabel: (base) => ({ ...base }),
};

  return (
    isFilterShown && (
        <div className="rightcontent">
          <div className="card">
            <div className="card-body">
              <Row>
                <Col lg={4}>
                  <h1 style={{ whiteSpace: "nowrap" }}>{t("Filters")}</h1>
                </Col>
                <Col lg={8}>
                  <div
                    className="hide-filter"
                    onClick={() => setFilterShown(false)}
                  >
                    {t("HideFilters")}
                  </div>
                </Col>
              </Row>

              <div className="rightcontent-fltr">

               {/* Select Name */}
               <div className="mb-3">
                  <div className="fltr-heading">
                    <label>{t("Ingredients")}</label>
                    <button onClick={onClearName}>{t("Clear")}</button>
                  </div> 

                  <SelectAsyncPaginate
                    placeholder={t("Select Ingredients")}
                    onChange={onProviderSelect}
                    value={selectedProviderIngreOption}
                    count={selectedProviderIngreOption.length}
                    datasource={providerOptions}
                    isMulti
                    query="provider-ingredients"
                    key="item_id"
                  />
                </div>
                
                {/* Select Providers */}  
                <div className="mb-3">
                  <div className="fltr-heading">
                    <label>{t("Providers")}</label>
                    <button onClick={onClearProviderName}>{t("Clear")}</button>
                  </div>

                  <Select
                    ref={selectProvIngredientRef}
                    styles={colourStyles}
                    value={selectedProviderOption}
                    onChange={setSelectedProviderOption}
                    components={makeAnimated()}
                    options={providersOptions}
                    isMulti
                    isSearchable
                    placeholder={t("SelectProvider")}
                    theme={(theme) => ({
                      ...theme,
                      borderRadius: 4,
                      colors: {
                        ...theme.colors,
                        danger: "#fff",
                        dangerLight: "hsl(53deg 2% 73%)",
                      },
                    })}
                  />
                </div>

                {/* ingredient types */}
                <div className="mt-4">
                    <div className="fltr-heading">
                      <label>{t("Categories")}</label>
                      <button onClick={onClearCategories}>{t("Clear")}</button>
                    </div>
                    <SelectInput
                        dataField="category"
                        placeholder={t("SelectCategory")}
                        defaultValue={NEW_INGREDIENT_CATEGORIES.find(
                          ({ id }) => id === category?.category
                        )}
                        options={NEW_INGREDIENT_CATEGORIES.slice(1).map((i) => ({
                          ...i,
                          label: t(i.label),
                        }))}
                        onChange={onSelectionChange}
                        value={category ? NEW_INGREDIENT_CATEGORIES.find(({ id }) => id === category?.category) : null}
                    />
                </div>
              </div>

              <div className="action-btns" style={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column"
              }}>
                <div
                  style={{
                    marginBottom: "7px"
                  }}
                >
                  <button onClick={onSaveFilter} className="close-btn me-3" style={{
                    width: '100%',
                    color : isFilterActive ? "#8b8f94" : "#6353ea"
                  }}>
                    {t("FixFilters")}
                  </button>
                </div>
                <div style={{
                  display: "flex"
                }}>
                  <button onClick={onClearAll} className="close-btn me-3">
                    <img src={CloseIcon} alt="" />
                    {t("Clear")}
                  </button>
                  <button onClick={() => onApply(false)} className="apply-btn">
                    {t("Apply")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
    )
  );
}

export default ProviderIngredientRightSide;
