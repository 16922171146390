import React, { useState, useEffect, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { utils, writeFile } from 'xlsx';
import { Spinner } from "react-bootstrap";
import axios from "axios";

import request from "services/request";
import PieChart from "components/pieChart";
import useCurrencySymbol from "customHooks/useCurrencySymbol";
import CustomTable from "components/customTable/index.tsx";
import TopAndFlopView from "views/commonViews/topAndFlop";

import { useUserData } from "contexts/AuthContextManagement";
import { useLoading } from "contexts/LoadingContextManagement";
import { SAMPLE_TOP_FLOPS } from "common/constants";

import { getTimezoneFormatUtc, timezoneFormat } from "common/utils";
import TelechargerIcon from "assets/images/telecharger.png";
import { getFinanceColumns, generateDemoTableData } from "./utils";
import {
  generateFinanceTableRawData,
  generateTotalEarnings,
  getRandomNumber,
} from "../occupancy/data";
import tableMealsDummy from "../../data/finance_meals.json";
import storage from "services/storage";
import { toast } from "react-toastify";

function LeftSide({ formData }) {
  const { t, i18n } = useTranslation();
  const { currencySymbol } = useCurrencySymbol();
  const { setError, setLoading } = useLoading();
  const {
    selectedRestaurant,
    selectedRestaurantId,
    isRestaurantLoaded,
    hasRetaurants,
  } = useUserData();

  const [filteredTableData, setFilteredTableData] = useState([]);
  const [filteredTableDataWithTotal, setFilteredTableDataWithTotal] = useState(
    []
  );
  const [totalEarnings, setTotalEarnings] = useState([]);
  const [isTop, setIsTop] = useState(true);
  const [topFlop, setTopFlop] = useState({ top: [], flop: [] });
  const [loadingDetails, setLoadingDetails] = useState(false);
  const [loadingTopFlop, setLoadingTopFlop] = useState(false);
  const [tableFinanceData ,setTableFinanceData] = useState(null);
  const [mealsLength, setMealsLength] = useState(0);
  const prevFromData = useRef(formData);
  const tableRef = useRef(null);
  const [progress, setProgress] = useState(0);
  const [downloading, setDownloading] = useState(false);
  const incrementInterval = useRef(null); // State to track download progress
  const totalActualRevenue = storage.getItem("total_actual_revenue");

  const financeColumns = useMemo(
    () =>
    getFinanceColumns(t, currencySymbol, hasRetaurants, isRestaurantLoaded, mealsLength, totalActualRevenue),
    [
      t,
      currencySymbol,
      hasRetaurants,
      isRestaurantLoaded,
      selectedRestaurant,
      selectedRestaurantId,
      mealsLength,
      totalActualRevenue
    ]
  );


  const mapper = (t) => ({
    ...t,
    meal: t.meal,
    sales: getRandomNumber(100, 1000),
  });

  // if(formData.meals && isRestaurantLoaded && !hasRetaurants )

  useEffect(() => {
    if (isRestaurantLoaded && !hasRetaurants) {
      setSampleTopFlop();
      generateDemoTableData(tableMealsDummy?.meals, formData.meals, isRestaurantLoaded, 
        hasRetaurants, setFilteredTableData, setTotalEarnings
      );
    }

    if (formData.meals.length && isRestaurantLoaded && !hasRetaurants) {
      generateDemoTableData(tableMealsDummy?.meals, formData.meals, isRestaurantLoaded, 
        hasRetaurants, setFilteredTableData, setTotalEarnings
      );
    }

    if (formData.meals.length && isRestaurantLoaded && !hasRetaurants) {
      setTopFlop({
        top: SAMPLE_TOP_FLOPS.TOP.map(mapper),
        flop: SAMPLE_TOP_FLOPS.FLOP.map(mapper),
      });
    }

    if (selectedRestaurantId !== "" && prevFromData.current !== formData) {
      getTopFlop();
      getFinanceData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData]);

  useEffect(() => {
    const totalRow = calculateSum(filteredTableData);
    const newData = [...filteredTableData];
    newData.unshift(totalRow);
     // Count items with margin greater than 0
    const countWithMargin  = newData?.filter(item => item?.unit_margin > 0)?.length;
    setMealsLength(countWithMargin  - 1);
    setFilteredTableDataWithTotal(newData);
  }, [filteredTableData]);

  const setSampleTopFlop = () => {
    setTopFlop({
      top: SAMPLE_TOP_FLOPS.TOP.map(mapper),
      flop: SAMPLE_TOP_FLOPS.FLOP.map(mapper),
    });
  };

  //get top and flop finance
  const getTopFlop = async () => {
    setLoadingTopFlop(true);
    let errorStatus = null;
    try {
      const payload = {
        restaurant_id: selectedRestaurantId,
        ...timezoneFormat(
          formData?.start_date,
          formData?.end_date,
          selectedRestaurant?.timezone
        ),
        restaurants: formData.myRestaurants,
        meals: formData.meals ?? [],
        meal_types: formData.mealTypes ?? [],
        ...(formData?.service_type && { service_type: formData.service_type })
        // ...(formData?.mealTypes?.length && {
        //   meal_types:
        //     formData?.mealTypes?.length === 1
        //       ? [...formData.mealTypes, ...formData.mealTypes]
        //       : formData.mealTypes,
        // }),
      };
      const result = await request.get(
        "top-and-flop/finance",
        payload,
        true,
        true,
        true
      );

      setTopFlop({ top: result.top, flop: result.flop });
      setLoadingTopFlop(false);
    } catch (e) {
      errorStatus = e?.status;
      if (e.status === 599) {
        // Keep loader true for retry of api request when we change the restaurant
        setLoading(true);
        setLoadingTopFlop(true);
        return;
      }
      if (e?.status !== 499) {
        setError("Unable to load Top Flop data.");
      }
    } finally {
      // Reset loader unless it's a `599` status
      if (errorStatus !== 599) {
        setLoading(false);
        setLoadingTopFlop(false);
      }
    }
  };

  const deleteItem = (row) => () => {
    let newTableData = filteredTableData.filter(
      (f) => f.name.toLowerCase() !== row.name.toLowerCase()
    );
    setFilteredTableData(() => newTableData);
  };

  const getFinanceData = async () => {
    const allowedFields = ["start_date", "end_date", "mealTypes", "meals", "myRestaurants", "total"];
    let errorStatus = null;
    try {
      setLoadingDetails(true);
      const result = await request.get(
        `tables/finance`,
        {
          restaurant_id: selectedRestaurantId,
          ...timezoneFormat(
            formData.start_date,
            formData.end_date,
            selectedRestaurant?.timezone
          ),
          restaurants: formData.myRestaurants,
          meals: formData.meals ?? [],
          meal_types: formData.mealTypes ?? [],
          ...(formData?.service_type && { service_type: formData.service_type })
        },
        true,
        true,
        true
      );

      // these are things are for temporary we have to do this by perfect way to do work on the backend side
      // Check if formData contains exactly the allowed fields
      const hasExactFields = Object.keys(formData).sort().toString() === allowedFields.sort().toString();

      if (
        hasExactFields &&
        formData.start_date &&
        formData.end_date &&
        formData.mealTypes.length === 0 &&
        formData.meals.length === 0 &&
        (formData.myRestaurants.length === 0 || formData.myRestaurants.length > 0) &&
        formData.total === true
      ) {
        storage.setItem("total_actual_revenue", result?.total_actual_revenue);
      }

      setTableFinanceData(result);
      generateTableData(result?.meals);
      setLoadingDetails(false);
    } catch (error) {
      errorStatus = error?.status;
      if (error.status === 599) {
        setLoading(true);
        setLoadingDetails(true);
        return;
      }
  
      if (error?.status !== 499) {
        setError("Unable to load Meal details.");
      }
    } finally {
      if (errorStatus !== 599) {
        setLoading(false);
        setLoadingDetails(false);
      }
    }
  };

  const generateTableData = (financeData = null) => {
    if (financeData) {
      const accuracyArr = []
      financeData.forEach((item)=>{
        const predictedRevenue = item?.predicted_revenue ?? 0;
        const actualRevenue = item?.actual_revenue ?? 0;
        
        const accuracy_revenue = (actualRevenue !== 0 && predictedRevenue !== 0) && (actualRevenue - predictedRevenue) < actualRevenue
        ? Math.max(0, (1 - Math.abs((actualRevenue - predictedRevenue) / actualRevenue)) * 100)
        : 0;

        return accuracyArr.push({
          ...item,
          accuracy_revenue: accuracy_revenue.toFixed(2)
        });
      })
      setFilteredTableData(accuracyArr);

      setTotalEarnings(() =>
        financeData?.map((m) => ({
          name: m.name,
          y: Number((m.qty * m.unit_price)?.toFixed(2)),
        }))
      );
      return;
    }

    setFilteredTableData(() =>
      generateFinanceTableRawData(
        formData,
        financeColumns.filter((c) => !["name", "action"].includes(c.dataField))
      )
    );
    setTotalEarnings(() => generateTotalEarnings(formData));
  };

  const simulateProgress = () => {
    incrementInterval.current = setInterval(() => {
      setProgress((prev) => (prev < 90 ? prev + 1 : prev));
    }, 100); // Increment every 100ms
  };

  const onDownload = async () => {
    setDownloading(true);
    setProgress(0);
    simulateProgress(); // Start simulating progress

    try {
      const token = storage.getItem("token"); // Replace with your token
      const payload = {
        restaurant_id: selectedRestaurantId,
        start_date: getTimezoneFormatUtc(formData.start_date, formData.end_date, selectedRestaurant?.timezone)?.start_date,
        end_date: getTimezoneFormatUtc(formData.start_date, formData.end_date, selectedRestaurant?.timezone)?.end_date,
        restaurants: formData.myRestaurants,
        meals: formData.meals ?? [],
        meal_types: formData.mealTypes ?? [],
        ...(formData?.service_type && { service_type: formData.service_type })
      }

      const response = await axios.get(
        `${process.env.REACT_APP_API_ENDPOINT}/revenues/details`,
        {
          params: payload,
          headers: { Authorization: `Bearer ${token}` },
          onDownloadProgress: (progressEvent) => {
            const total = progressEvent.total || progressEvent.loaded;
            const currentProgress = Math.round((progressEvent.loaded / total) * 100);

            // Stop the interval when the actual progress is 100%
            if (currentProgress >= 100) {
              clearInterval(incrementInterval.current);
            }

            // Set actual progress
            setProgress(currentProgress);
          },
        }
      );

      // Process your data and write to Excel
      setTableFinanceData(response.data);
      generateTableData(response.data?.meals); // Assuming response data contains the table data
      // Add static data

      const ws = utils.table_to_sheet(tableRef.current);
      const staticData = [
        [`${t("Restaurant name")}: ${selectedRestaurant.name}`], // A1
        [`${t("Start date")}: ${formData.start_date}`], // A2
        [`${t("End date")}: ${formData.end_date}`], // A3
        [],
        []
      ];

      // Combine static data and table data
      const tableData = utils.sheet_to_json(ws, { header: 1 }); // Convert existing sheet data to array
      const combinedData = [...staticData, ...tableData]; // Combine static and table data

    // Create a new sheet with combined data
  const newWs = utils.aoa_to_sheet(combinedData);

    // Adjust the file name
    const fileName = i18n.language === 'fr' 
    ? `${selectedRestaurant.name}_Extraction de données financières_${new Date().toLocaleDateString()}`
    : `${selectedRestaurant.name}_Fincancials extraction_${new Date().toLocaleDateString()}`;

    // Create a new workbook and append the sheet
    const wb = utils.book_new();
    utils.book_append_sheet(wb, newWs, 'Sheet1');

      // Download the Excel file
      writeFile(wb, `${fileName}.xlsx`);
      toast.success(t("Meals Downloaded successfully"))
      // Ensure progress reaches 100% after the file download completes
      setProgress(100);
    } catch (error) {
      toast.error(t("Meals Download Failed"))
      console.error("Error downloading the file:", error);
      clearInterval(incrementInterval.current);
      setProgress(0); // Reset progress in case of an error
    } finally {
      setDownloading(false);
      clearInterval(incrementInterval.current);
    }
  };

  //To calculate the total
  const calculateSum = (data) => {
    const additionalRow = {
      id: 0,
      name: "Total",
      qty: 0,
      // unit_cost: 0,
      total_cost: 0,
      unit_price: 0,
      total_price: 0,
      actual_revenue: 0,
      predicted_revenue: 0,
      unit_margin: 0,
      accuracy_revenue: 0
    };

    if (data.length > 0) {
      for (const item of data) {
        additionalRow.qty += item.qty;
        // additionalRow.unit_cost += item.unit_cost ?? 0;
        additionalRow.total_cost += item.total_cost ?? 0;
        additionalRow.unit_price += item.unit_price ?? 0;
        additionalRow.total_price += item.total_price ?? 0;
        additionalRow.actual_revenue += item.actual_revenue ?? 0;
        additionalRow.predicted_revenue += item.predicted_revenue ?? 0;
        additionalRow.unit_margin += +item.unit_margin / data.length;
        additionalRow.accuracy_revenue += parseFloat(item.accuracy_revenue) ?? 0
      }
      // additionalRow.unit_cost = parseFloat(additionalRow.unit_cost.toFixed(2));
      additionalRow.total_cost = parseFloat(
        additionalRow.total_cost.toFixed(2)
      );
      additionalRow.unit_price = parseFloat(
        additionalRow.unit_price.toFixed(2)
      );
      additionalRow.total_price = parseFloat(
        additionalRow.total_price.toFixed(2)
      );
      additionalRow.actual_revenue = parseFloat(
        additionalRow.actual_revenue.toFixed(2)
      );
      additionalRow.predicted_revenue = parseFloat(
        additionalRow.predicted_revenue.toFixed(2)
      );
      additionalRow.unit_margin = parseFloat(
        additionalRow.unit_margin.toFixed(2)
      );
      additionalRow.accuracy_revenue = parseFloat(
        additionalRow.accuracy_revenue.toFixed(2)
      );
    }
    if (tableFinanceData?.total_predicted_revenue) {
      additionalRow.predicted_revenue = tableFinanceData?.total_predicted_revenue;
    }
    if ( tableFinanceData?.total_actual_revenue) {
      additionalRow.actual_revenue = tableFinanceData?.total_actual_revenue;
    }
    if ( tableFinanceData?.total_cost) {
      additionalRow.total_cost = tableFinanceData?.total_cost;
    }
    if ( tableFinanceData?.total_qty) {
      additionalRow.qty = tableFinanceData?.total_qty;
    }

    return additionalRow;
  };

  return (
    <div className="leftcontent leftcontent-finance">
    {downloading && (
        <div>
        <span className="downloading-text">{t("Downloading Meals in progress....")}</span>
        <div className="progress-bar-container">
          <div
            className="progress-bar"
            style={{ width: `${progress}%` }}
          >{`${progress}%`}</div>
        </div>
        </div>
      )}
      <div className="card first-card">
        <div className="card-header d-flex align-items-center justify-content-between">
          <h2>{t("MealsDetails")}</h2>
          <button onClick={onDownload} className="btn btn-white btn-icon me-1" style={{ padding: "2px 13px"}}>
          <img src={TelechargerIcon} alt="" className="m-0" />
        </button>
        </div>
        <div className="card-body inherit-height">
          {loadingDetails ? (
            <div className="w-100 d-flex justify-content-center card-spinner-container">
              <Spinner animation="border" variant="primary" />
            </div>
          ) : (
            <CustomTable
              ref={tableRef}
              columns={financeColumns}
              data={filteredTableDataWithTotal}
              deleteRow={deleteItem}
            />
          )}
        </div>
      </div>
      <div className="row second-card">
        <div className="col-lg-9 h-100">
          <div className="card h-100">
            <div className="card-header">
              <h2>{t("TotalEarnings")}</h2>
            </div>
            <div className="card-body h-100">
              {loadingDetails && (
                <div className="w-100 d-flex justify-content-center card-spinner-container">
                  <Spinner animation="border" variant="primary" />
                </div>
              )}
              {!loadingDetails && (
                <PieChart
                  data={totalEarnings}
                  formatterPrefix={` ${currencySymbol}`}
                />
              )}
            </div>
          </div>
        </div>
        <div className="col-md-3">
          <div className="card cardlist mb-0">
            <div className="card-body">
              <h2 className="mb-3">{t("Sales")}</h2>

              <ul className=" navbtns ">
                <li className={`${isTop ? "active" : ""}`}>
                  <button
                    className={`nav-link ${isTop ? "active" : ""}`}
                    onClick={() => setIsTop(true)}
                  >
                    {t("Top")}
                  </button>
                </li>
                <li className={`${!isTop ? "active" : ""}`}>
                  <button
                    className={`nav-link ${!isTop ? "active" : ""}`}
                    onClick={() => setIsTop(false)}
                  >
                    {t("Flop")}
                  </button>
                </li>
              </ul>
              <div className="top-flop">
                {loadingTopFlop && (
                  <div className="w-100 d-flex justify-content-center card-spinner-container">
                    <Spinner animation="border" variant="primary" />
                  </div>
                )}
                {isTop && !loadingTopFlop && (
                  <TopAndFlopView data={topFlop.top} />
                )}
                {!isTop && !loadingTopFlop && (
                  <TopAndFlopView data={topFlop.flop} />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LeftSide;
