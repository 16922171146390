import React, { useState, Fragment, useEffect } from "react";
import Form from "react-bootstrap/Form";
import DatePicker from "react-datepicker";
import moment from "moment";
import { Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { useUserData } from "contexts/AuthContextManagement";
import { useFilterData } from "contexts/FilterContextManagment";

import CloseIcon from "assets/images/close.png";
import CalendarIcon from "assets/images/calendar_datepicker.png";
import DeleteIcon from "assets/images/icon/DELETE_WHITE.svg";

import "react-datepicker/dist/react-datepicker.css";
import { EVENT_TYPES } from "common/constants";

const STATUS_CHECKBOXES = [
  { name: "no_expiry", label: "Not expired" },
  { name: "unsold", label: "Unsold" },
];

const EXPIRY_CHECKBOXES = [
  { name: "good_to_eat", label: "Good to eat" },
  { name: "soon_to_expire", label: "Soon to be expired" },
  { name: "expired", label: "Expired" },
];

/**
 * function to render the filter screen of the sctock page
 * @param {*} param0
 * @returns UI component
 */
const START_DATE = new Date(new Date().setDate(new Date().getDate()));
const END_DATE = new Date(new Date().setDate(new Date().getDate()));

function RightSide({ onApply: onSubmit, formData: fd }) {
  const { t } = useTranslation();
  const {
    eventTypeData,
    setEventTypeData,
    filterStartEndDate,
    setFilterStartEndDate
  } = useFilterData();

  const {
    isFilterShown,
    isRestaurantLoaded,
    hasRetaurants,
    setFilterShown,
    selectedRestaurantId,
  } = useUserData();

  const [selectedOption, setSelectedOption] = useState([]);
  const [isStartDateOpen, setIsStartDateOpen] = useState(false);
  const [isEndDateOpen, setIsEndDateOpen] = useState(false);
  const [formData, setFormData] = useState(eventTypeData ?? {});
  const [startEndDate, setStartEndDate] = useState({
    start_date: filterStartEndDate && filterStartEndDate.start_date !== "" ? filterStartEndDate.start_date : START_DATE,
    end_date: filterStartEndDate && filterStartEndDate.end_date !== "" ? filterStartEndDate.end_date : END_DATE,
  });

  /**
   * to be called whenever the user selects the date from date-window
   * @param {*} name field name
   * @returns
   */
  
  const onDateChange = (name) => (e) => {
    setStartEndDate({ ...startEndDate, [name]: e });
    setIsStartDateOpen(false);
    setIsEndDateOpen(false);
  };

  useEffect(() => {
    onClearAll();
  }, [selectedRestaurantId]);

  useEffect(() => {
    setFormData(eventTypeData);
    if (filterStartEndDate && filterStartEndDate.start_date !== "" && filterStartEndDate.end_date !== "") {
      setStartEndDate(filterStartEndDate);
    } else {
      setStartEndDate(startEndDate);
    }
    onApply();
  }, [])

  /**
   * submits the selected filters to api
   * @param {*} isInitial
   */
  const onApply = (isInitial = false) => {
    const payload = JSON.parse(
      JSON.stringify({ ...(isInitial ? {} : formData), ...startEndDate })
    );
    const getFormattedDate = (d) => moment(d).format("YYYY-MM-DD");

    if (payload.start_date) {
      payload.start_date = getFormattedDate(payload.start_date);
    }
    if (payload.end_date) {
      payload.end_date = getFormattedDate(payload.end_date);
    }

    if (typeof isInitial === "boolean" && isInitial !== false) {
      payload.start_date = payload.end_date = moment().format("YYYY-MM-DD");
      STATUS_CHECKBOXES.map((x) => x.name).forEach((t) => delete payload[t]);
      EXPIRY_CHECKBOXES.map((x) => x.name).forEach((t) => delete payload[t]);
    }
    onSubmit({
      ...payload,
    });
  };

  /**
   * update various filter options
   * @param {*} param0
   */

  const onCheckboxChange =
  (type) =>
  ({ target: { checked, name } }) => {
    setFormData({
      ...formData,
      ...(type === "eventTypes" && {
        type: name
      }),
    });
  };

  const customCheckbox = ({ name, label, checked, type = null }) => {
    return (
      <Form.Group className="mb-3">
        <label className="checkbox">
          <input
            type="checkbox"
            onChange={onCheckboxChange(type)}
            name={name}
            checked={checked}
          />
          <span className="me-2" />
          {label}
        </label>
      </Form.Group>
    );
  };

  const onClearDates = () => {
    setStartEndDate({
      start_date: new Date(),
      end_date: new Date(),
    });
  };

  const onClearAll = (apply = true) => {
    onClearDates();
    setFormData({});
    onClearProducts();

    if (apply) onApply(true);
  };
  const setDateOpen =
    (type, checked = false) =>
    () => {
      type === "start_date"
        ? setIsStartDateOpen(checked)
        : setIsEndDateOpen(checked);
    };

  const onClearProducts = () => {
    setSelectedOption([]);
  };

  const onIngredientSelect = (type) => (v) => setSelectedOption([...v]);

  const onClearEventTypes = () => {
    const { type, ...formDataWithoutType } = formData;
    setFormData(formDataWithoutType);
  };

  const onSaveFilter = () => {
    setEventTypeData(formData);
    setFilterStartEndDate(startEndDate);
  }
  return (
    isFilterShown && (
        <div className="rightcontent">
          <div className="card">
            <div className="card-body">
              <Row>
                <Col lg={4}>
                  <h1 style={{ whiteSpace: "nowrap" }}>{t("Filters")}</h1>
                </Col>
                <Col lg={8}>
                  <div
                    className="hide-filter"
                    onClick={() => setFilterShown(false)}
                  >
                    {t("HideFilters")}
                  </div>
                </Col>
              </Row>

              <div className="rightcontent-fltr">
                <div className="mb-5">
                  <div className="fltr-heading">
                    <label>{t("Date")}</label>
                    <button onClick={onClearDates}>{t("Clear")}</button>
                  </div>
                  <div className="d-flex datepicker-wrapper">
                    <span>{t("Start")}</span>
                    <div className="datepicker-wrapper-img">
                      <DatePicker
                        selected={new Date(startEndDate.start_date)}
                        dateFormat="dd MMM yyyy"
                        placeholderText={t("Start date")}
                        onChange={onDateChange("start_date")}
                        className="date-picker"
                        open={isStartDateOpen}
                        onClickOutside={setDateOpen("start_date")}
                        onClick={setDateOpen("start_date", true)}
                        // minDate={new Date()}
                      />
                      <img
                        src={CalendarIcon}
                        onClick={setDateOpen("start_date", !isStartDateOpen)}
                        alt=""
                      />
                    </div>
                  </div>
                  <hr className="hr-separator" />
                  <div className="d-flex datepicker-wrapper">
                    <span>{t("End")}&nbsp;&nbsp;</span>
                    <div className="datepicker-wrapper-img">
                      <DatePicker
                        selected={new Date(startEndDate.end_date)}
                        dateFormat="dd MMM yyyy"
                        placeholderText={t("End date")}
                        onChange={onDateChange("end_date")}
                        className="date-picker"
                        open={isEndDateOpen}
                        onClickOutside={setDateOpen("end_date")}
                        onClick={setDateOpen("end_date", true)}
                        // minDate={new Date()}
                      />
                      <img
                        src={CalendarIcon}
                        onClick={setDateOpen("end_date", !isEndDateOpen)}
                        alt=""
                      />
                    </div>
                  </div>
                </div>

                <div className="mt-4">
                    <div className="fltr-heading">
                      <label>{t("Types")}</label>
                      <button onClick={onClearEventTypes}>{t("Clear")}</button>
                    </div>
                    {((isRestaurantLoaded && !hasRetaurants) ? EVENT_TYPES : EVENT_TYPES).map((check, i) => {
                      const label = t(check.label);
                      return (
                        <Fragment key={i}>
                          {customCheckbox({
                            name: check.id,
                            label,
                            checked: formData?.["type"] == check.id || false,
                            type: "eventTypes",
                          })}
                        </Fragment>
                      );
                    })}
                </div>
              </div>

              <div className="action-btns" style={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column"
              }}>
                <div
                  style={{
                    marginBottom: "7px"
                  }}
                >
                  <button onClick={onSaveFilter} className="close-btn me-3" style={{
                    width: '100%'
                  }}>
                    {t("FixFilters")}
                  </button>
                </div>
                <div style={{
                  display: "flex"
                }}>
                  <button onClick={onClearAll} className="close-btn me-3">
                    <img src={CloseIcon} alt="" />
                    {t("Clear")}
                  </button>
                  <button onClick={() => onApply(false)} className="apply-btn">
                    {t("Apply")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
    )
  );
}

export default RightSide;
