import React, { useState, useEffect, useRef } from "react";
import { Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import SelectInput from "components/selectInput";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import SelectAsyncPaginate from "components/selectAsyncPaginate/index.tsx";
import request from "services/request";

import { useUserData } from "contexts/AuthContextManagement";
import { useFilterData } from "contexts/FilterContextManagment";

import CloseIcon from "assets/images/close.png";
import DeleteIcon from "assets/images/icon/DELETE_WHITE.svg";

import { NEW_INGREDIENT_CATEGORIES } from "common/constants";
import tableIngredientsDummy from "../../../data/ingredients.json";
import tableProviderIngredientsDummy from "../../../data/provider_ingredients.json";

import "react-datepicker/dist/react-datepicker.css";

/**
 * function to render the filter screen of the sctock page
 * @param {*} param0
 * @returns UI component
 */

function RightSide({ onApply: onSubmit, formData: fd }) {
  const { t } = useTranslation();
  const { 
    eventTypeData, 
    setEventTypeData, 
    selectedIngredients, 
    setSelectedIngredients, 
    selectedProviders, 
    setSelectedProviders,
    selectedCategories,
    setSelectedCategories,
    isFilterActive,
    updateFilterButton
  } = useFilterData();

  const {
    isFilterShown,
    isRestaurantLoaded,
    hasRetaurants,
    setFilterShown,
    selectedRestaurant,
    selectedRestaurantId,
  } = useUserData();

  const selectProvIngredientRef = useRef(null);
  const [category, setCategory] = useState(selectedCategories ?? {});
  const [formData, setFormData] = useState(eventTypeData ?? {});
  const [providers, setProviders] = useState([]);
  const [selectedProviderOption, setSelectedProviderOption] = useState( selectedProviders ?? []);
  const [selectedIngredientOption, setSelectedIngredientOption] = useState(selectedIngredients ?? []);
  /**
   * to be called whenever the user selects the date from date-window
   * @param {*} name field name
   * @returns
   */
  const onIngredSelect = () => (v) => {
    setSelectedIngredientOption([...v]);
  };

  useEffect(() => {
    onClearAll();
  }, [selectedRestaurantId]);

  useEffect(() => {
    if (isRestaurantLoaded && !hasRetaurants) {
      return;
    }
    getProviderIngredients();
    setFormData(eventTypeData);
    setSelectedIngredientOption(selectedIngredients);
    setSelectedProviderOption(selectedProviders);
    setCategory(selectedCategories);
    onApply();
  }, [])

  const providersOptions = providers?.map((obj) => ({
    value: obj.id,
    label: obj.name,
  }));

  /**
   * submits the selected filters to api
   * @param {*} isInitial
   */


  
  const onApply = (isInitial = false) => {
    const force = typeof isInitial === "boolean" ? isInitial : false; // use to forcefully send default filter states/values on clear button

    const payload = JSON.parse(
      JSON.stringify({ ...(isInitial ? {} : formData) })
    );

    onSubmit({
      ingredients: selectedIngredientOption.map((s) => s.id),
      providers: selectedProviderOption.map((s) => s.value),
      category: category,
      ...payload,
      ...(force && {
        ingredients: [],
        providers: [],
        category: {}
      }),
    });
  };

  /**
   * update various filter options
   * @param {*} param0
   */

  const onClearAll = (apply = true) => {
    setFormData({});
    onClearCategories();
    onClearIngredients();
    onClearProviderName();
    setSelectedProviderOption([]);

    if (apply) onApply(true);
  };

  const onClearCategories = () => {
    setCategory(null);
  };

  const onSaveFilter = () => {
    setEventTypeData(formData);
    setSelectedIngredients(selectedIngredientOption);
    setSelectedProviders(selectedProviderOption);
    setSelectedCategories(category);
    updateFilterButton(true);
  }
  
  const getProviderIngredients = async() => {
    const payload = {
      restaurant_id: selectedRestaurantId,
    };
    const response = await request.get(`providers`, payload);
    setProviders(response.providers);
  };

  const onClearProviderName = () => {
    if (selectProvIngredientRef?.current) {
      selectProvIngredientRef.current.clearValue();
    }
   }

  const options = tableIngredientsDummy.ingredients?.map((m) => ({
    name: m.name,
    label: m.name,
    item_id: m.name,
  }));

  const providerOptions = tableProviderIngredientsDummy.provider_ingredients?.map((m) => ({
    name: m.name,
    label: m.name,
    item_id: m.name,
  }));

  const onSelectionChange = (type) => ({ id }) => {
     setCategory({ [type]: id });
  };

  const onClearIngredients = () => {
    setSelectedIngredientOption([]);
  };

  const colourStyles = {
    option: (styles, { data, isDisabled, isFocused, isSelected, ...args }) => {
      return {
        ...styles,
        backgroundColor: isDisabled
          ? undefined
          : isSelected
          ? data.color
          : isFocused
          ? "#F3F4FB"
          : undefined,
        color: isDisabled ? "#ccc" : isSelected ? "black" : "black",
      };
    },
    multiValueLabel: (base) => ({ ...base }),
  };
  return (
    isFilterShown && (
        <div className="rightcontent">
          <div className="card">
            <div className="card-body">
              <Row>
                <Col lg={4}>
                  <h1 style={{ whiteSpace: "nowrap" }}>{t("Filters")}</h1>
                </Col>
                <Col lg={8}>
                  <div
                    className="hide-filter"
                    onClick={() => setFilterShown(false)}
                  >
                    {t("HideFilters")}
                  </div>
                </Col>
              </Row>

              <div className="rightcontent-fltr">
                
                {/* select ingredient */}
                <div className="mb-3">
                  <div className="fltr-heading">
                    <label>{t("Ingredients")}</label>
                    <button onClick={onClearIngredients}>{t("Clear")}</button>
                  </div>

                  <SelectAsyncPaginate
                    {...(!selectedRestaurant && {
                      datasource: options,
                    })}
                    placeholder={t("Select Ingredients")}
                    onChange={onIngredSelect}
                    value={selectedIngredientOption}
                    count={selectedIngredientOption.length}
                    isMulti
                    query="ingredients"
                    key="item_id"
                  />
                </div>

                {/* Search Provider */}
                <div className="mb-3">
                  <div className="fltr-heading">
                    <label>{t("Providers")}</label>
                    <button onClick={onClearProviderName}>{t("Clear")}</button>
                  </div>

                  <Select
                    ref={selectProvIngredientRef}
                    styles={colourStyles}
                    value={selectedProviderOption}
                    onChange={setSelectedProviderOption}
                    components={makeAnimated()}
                    options={providersOptions}
                    isMulti
                    isSearchable
                    placeholder={t("SelectProvider")}
                    theme={(theme) => ({
                      ...theme,
                      borderRadius: 4,
                      colors: {
                        ...theme.colors,
                        danger: "#fff",
                        dangerLight: "hsl(53deg 2% 73%)",
                      },
                    })}
                  />
                </div>

                {/* ingredient types */}
                <div className="mt-4">
                    <div className="fltr-heading">
                      <label>{t("Categories")}</label>
                      <button onClick={onClearCategories}>{t("Clear")}</button>
                    </div>
                    <SelectInput
                      dataField="category"
                      placeholder={t("SelectCategory")}
                      options={NEW_INGREDIENT_CATEGORIES.slice(1).map((i) => ({
                      ...i, label: t(i.label),
                      }))}
                      onChange={onSelectionChange}
                      defaultValue={NEW_INGREDIENT_CATEGORIES.find(
                         ({ id }) => id === category?.category
                      )}
                      value={category ? NEW_INGREDIENT_CATEGORIES.find(({ id }) => id === category?.category) : null}
                    />
                </div>
              </div>

              <div className="action-btns" style={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column"
              }}>
                <div
                  style={{
                    marginBottom: "7px"
                  }}
                >
                  <button onClick={onSaveFilter} className="close-btn me-3" style={{
                    width: '100%',
                    color : isFilterActive ? "#8b8f94" : "#6353ea"
                  }}>
                    {t("FixFilters")}
                  </button>
                </div>
                <div style={{
                  display: "flex"
                }}>
                  <button onClick={onClearAll} className="close-btn me-3">
                    <img src={CloseIcon} alt="" />
                    {t("Clear")}
                  </button>
                  <button onClick={() => onApply(false)} className="apply-btn">
                    {t("Apply")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
    )
  );
}

export default RightSide;
